import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// Helper functions for deck and card values
const createDeck = (numDecks) => {
  const suits = ['♠', '♥', '♦', '♣'];
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
  let deck = [];
  for (let i = numDecks; i > 0; i--) {
    for (let suit of suits) {
      for (let value of values) {
        deck.push({ value, suit });
      }
    }
  }
  return shuffleDeck(deck);
};

const shuffleDeck = (deck) => {
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
  return deck;
};

const getCardValue = (card) => {
  if (!card || !card.value) return 0;
  if (['J', 'Q', 'K'].includes(card.value)) return 10;
  if (card.value === 'A') return 11;
  return parseInt(card.value);
};

const calculateHandValue = (hand) => {
  let value = hand.reduce((acc, card) => acc + getCardValue(card), 0);
  let aces = hand.filter(card => card && card.value === 'A').length;
  while (value > 21 && aces > 0) {
    value -= 10;
    aces -= 1;
  }
  return value;
};

const Blackjack = () => {
  const [numDecks, setNumDecks] = useState(2);
  const [deck, setDeck] = useState(createDeck(numDecks));
  const [playerHands, setPlayerHands] = useState([[]]);
  const [dealerHand, setDealerHand] = useState([]);
  const [dealerTurn, setDealerTurn] = useState(false);
  const [currentHandIndex, setCurrentHandIndex] = useState(0);
  const [playerOptionsDisabled, setPlayerOptionsDisabled] = useState(false);
  const [handMessages, setHandMessages] = useState([]);
  const [handResults, setHandResults] = useState([]);
  const [remainingCards, setRemainingCards] = useState(deck.length);
  const [handsPlayed, setHandsPlayed] = useState(0);
  const [handsWon, setHandsWon] = useState(0);
  const [correctMoves, setCorrectMoves] = useState(0);
  const [totalMoves, setTotalMoves] = useState(0);
  const [moveHistory, setMoveHistory] = useState([]);
  const [pastHands, setPastHands] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handleEndOfHand = () => {
    let newHandResults = [...handResults];
    let dealerValue = calculateHandValue(dealerHand);
    playerHands.forEach((hand, index) => {
      let playerValue = calculateHandValue(hand);
      let handResult = '';
      if (playerValue > 21) {
        newHandResults[index] = 'LOSE';
        handResult = 'LOSE';
      } else if (dealerValue > 21 || playerValue > dealerValue || playerValue === 21) {
        newHandResults[index] = 'WIN';
        handResult = 'WIN';
        setHandsWon(handsWon + 1);
      } else if (playerValue === dealerValue) {
        newHandResults[index] = 'PUSH';
        handResult = 'PUSH';
      } else {
        newHandResults[index] = 'LOSE';
        handResult = 'LOSE';
      }
      setPastHands(prev => [...prev, { playerValue, dealerValue, handResult }]);
    });
    setHandResults(newHandResults);
    setHandsPlayed(handsPlayed + playerHands.length);
  };

  const dealInitialCards = () => {
    const newDeck = [...deck];
    const playerHand = [newDeck.pop(), newDeck.pop()];
    const dealerHand = [newDeck.pop(), newDeck.pop()];

    setDeck(newDeck);
    setRemainingCards(newDeck.length);
    setPlayerHands([playerHand]);
    setDealerHand(dealerHand);
    setCurrentHandIndex(0);
    setPlayerOptionsDisabled(false);
    setHandMessages([]);
    setHandResults([]);
    setMoveHistory([]);
  };

  useEffect(() => {
    dealInitialCards();
  }, [numDecks]);

  const handleDeckChange = (event) => {
    const selectedDecks = parseInt(event.target.value);
    setNumDecks(selectedDecks);
    setDeck(createDeck(selectedDecks));
  };

  const startNewRound = () => {
    setDeck(createDeck(numDecks));
    setRemainingCards(deck.length);
    setPlayerHands([[]]);
    setDealerHand([]);
    setCurrentHandIndex(0);
    setPlayerOptionsDisabled(false);
    setDealerTurn(false);
    setHandMessages([]);
    setHandResults([]);
    setMoveHistory([]);
    dealInitialCards();
  };

  const hit = () => {
    if (playerOptionsDisabled) return;
    const newDeck = [...deck];
    const newCard = newDeck.pop();
    const newHands = [...playerHands];
    newHands[currentHandIndex] = [...newHands[currentHandIndex], newCard];
    setDeck(newDeck);
    setRemainingCards(newDeck.length);
    setPlayerHands(newHands);

    const handValue = calculateHandValue(newHands[currentHandIndex]);
    if (handValue === 21) {
      handleWinImmediately();
    } else if (handValue > 21) {
      setCurrentHandIndex(currentHandIndex + 1);
      if (currentHandIndex >= newHands.length - 1) {
        setPlayerOptionsDisabled(true);
        setDealerTurn(true);
      }
    }
  };

  const handleWinImmediately = () => {
    let newHandResults = [...handResults];
    newHandResults[currentHandIndex] = 'WIN';
    setHandResults(newHandResults);
    setHandsWon(handsWon + 1);
    setCurrentHandIndex(currentHandIndex + 1);
    if (currentHandIndex >= playerHands.length - 1) {
      setPlayerOptionsDisabled(true);
      setDealerTurn(true);
    }
  };

  const stand = () => {
    const currentHand = playerHands[currentHandIndex];
    const handValue = calculateHandValue(currentHand);
    if (handValue === 21) {
      handleWinImmediately();
    } else if (currentHandIndex < playerHands.length - 1) {
      setCurrentHandIndex(currentHandIndex + 1);
    } else {
      setPlayerOptionsDisabled(true);
      setDealerTurn(true);
      handleEndOfHand();
    }
  };

  const doubleDown = () => {
    hit();
    stand();
  };

  const splitPairs = () => {
    const newHands = [...playerHands];
    const currentHand = playerHands[currentHandIndex];
    const newDeck = [...deck];
    const newHand1 = [currentHand[0], newDeck.pop()];
    const newHand2 = [currentHand[1], newDeck.pop()];
    newHands.splice(currentHandIndex, 1, newHand1, newHand2);
    setPlayerHands(newHands);
    setDeck(newDeck);
    setRemainingCards(newDeck.length);
  };

  const takeInsurance = () => {
    // Implement insurance functionality
  };

  const showMoveAdvice = (message) => {
    setPopupContent(message);
    setShowPopup(true);
  };

  // Card style, maintaining size
  const cardStyle = {
    border: `1px solid #333`,
    borderRadius: '5px',
    padding: '10px',
    margin: '10px',
    display: 'inline-block',
    backgroundColor: '#fff',
    fontSize: '24px',  // Increased font size
    fontWeight: 'bold',
    color: '#333',
    width: '60px',  // Increased card size
    height: '80px',
    textAlign: 'center',
    lineHeight: '70px',
  };

  const hiddenCardStyle = {
    ...cardStyle,
    backgroundColor: '#fff',
    color: '#333',
  };

  // Player and dealer hand container styles reduced by 10%
  const getHandContainerStyle = (index) => {
    if (handResults[index] === 'WIN') {
      return {
        ...handContainerStyle,
        backgroundColor: '#d4edda',
        borderColor: '#28a745',
      };
    } else if (handResults[index] === 'LOSE') {
      return {
        ...handContainerStyle,
        backgroundColor: '#f8d7da',
        borderColor: '#dc3545',
      };
    } else if (handResults[index] === 'PUSH') {
      return {
        ...handContainerStyle,
        backgroundColor: '#fff3cd',
        borderColor: '#ffc107',
      };
    }
    return handContainerStyle;
  };

  const handContainerStyle = {
    marginBottom: '18px',  // Reduced by 10%
    padding: '18px',  // Reduced by 10%
    borderRadius: '5px',
    border: `2px solid #333`,
    color: '#333',
    textAlign: 'center',
    width: '360px',  // Reduced width by 10%
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const scoreStyle = {
    marginTop: '13px',
    fontWeight: 'bold',
    fontSize: '22px',  // Reduced by 10%
  };

  const labelStyle = {
    fontWeight: 'bold',
    fontSize: '22px',  // Reduced by 10%
    marginBottom: '9px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    margin: '10px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#006400',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    minWidth: '120px',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30px',
  };

  const buttonsContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    gap: '20px',
    flexWrap: 'wrap',
  };

  const StatisticsList = () => {
    return (
      <ul style={{ listStyleType: 'none', padding: 0, fontSize: '18px' }}>
        <li>Remaining Cards: {remainingCards}</li>
        <li>Hands Played: {handsPlayed}</li>
        <li>Hands Won: {handsWon}</li>
        <li>Total Moves: {totalMoves}</li>
        <li>Correct Moves: {correctMoves}</li>
      </ul>
    );
  };

  const tableHeaderStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '18px',
  };

  const tableCellStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    textAlign: 'center',
    fontSize: '18px',
  };

  const StrategyTable = () => {
    const decisionStyles = {
      'Hit': { backgroundColor: '#ADD8E6' },
      'Stand': { backgroundColor: '#ffffff' },
      'Split': { backgroundColor: '#87CEEB' },
      'Double Down / Hit': { backgroundColor: '#9ACD32' },
      'Double Down / Stand': { backgroundColor: '#32CD32' },
      'Split / Hit': { backgroundColor: '#FFA07A' },
      'Surrender / Hit': { backgroundColor: '#9370DB' },
    };

    const strategyData = [
      { hand: '9', moves: ['Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '10', moves: ['Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit'] },
      { hand: '11', moves: ['Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit'] },
      { hand: '12', moves: ['Hit', 'Hit', 'Stand', 'Stand', 'Stand', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '13', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '14', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Hit', 'Hit', 'Surrender / Hit', 'Surrender / Hit', 'Hit'] },
      { hand: '15', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Hit', 'Surrender / Hit', 'Surrender / Hit', 'Surrender / Hit', 'Hit'] },
      { hand: '16', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Surrender / Hit', 'Surrender / Hit', 'Surrender / Hit', 'Surrender / Hit', 'Surrender / Hit'] },
      { hand: 'A,2', moves: ['Hit', 'Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,3', moves: ['Hit', 'Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,4', moves: ['Hit', 'Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,5', moves: ['Hit', 'Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,6', moves: ['Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,7', moves: ['Stand', 'Double Down / Stand', 'Double Down / Stand', 'Double Down / Stand', 'Double Down / Stand', 'Stand', 'Stand', 'Hit', 'Hit', 'Hit'] },
      { hand: 'A,8', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: 'A,9', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: '18', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: '19', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: '20', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: '21', moves: ['Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand', 'Stand'] },
      { hand: '2,2', moves: ['Split / Hit', 'Split / Hit', 'Split', 'Split', 'Split', 'Split', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '3,3', moves: ['Split / Hit', 'Split / Hit', 'Split', 'Split', 'Split', 'Split', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '4,4', moves: ['Hit', 'Hit', 'Hit', 'Split / Hit', 'Split / Hit', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '5,5', moves: ['Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Double Down / Hit', 'Hit', 'Hit'] },
      { hand: '6,6', moves: ['Split / Hit', 'Split / Hit', 'Split', 'Split', 'Split', 'Hit', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '7,7', moves: ['Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Hit', 'Hit', 'Hit', 'Hit'] },
      { hand: '8,8', moves: ['Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split'] },
      { hand: '9,9', moves: ['Split', 'Split', 'Split', 'Split', 'Split', 'Stand', 'Split', 'Split', 'Stand', 'Stand'] },
      { hand: 'A,A', moves: ['Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split', 'Split'] },
    ];

    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h2>Blackjack Strategy</h2>
        <table style={{ width: '90%', margin: '20px auto', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Your Hand</th>
              <th style={tableHeaderStyle}>2</th>
              <th style={tableHeaderStyle}>3</th>
              <th style={tableHeaderStyle}>4</th>
              <th style={tableHeaderStyle}>5</th>
              <th style={tableHeaderStyle}>6</th>
              <th style={tableHeaderStyle}>7</th>
              <th style={tableHeaderStyle}>8</th>
              <th style={tableHeaderStyle}>9</th>
              <th style={tableHeaderStyle}>10</th>
              <th style={tableHeaderStyle}>A</th>
            </tr>
          </thead>
          <tbody>
            {strategyData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td style={tableCellStyle}>{row.hand}</td>
                {row.moves.map((move, moveIndex) => (
                  <td key={moveIndex} style={{ ...tableCellStyle, ...decisionStyles[move] }}>{move}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Router>
      <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#fff', color: '#333', minHeight: '100vh' }}>
        <header style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '1px solid #333' }}>
          <div style={{ textAlign: 'left', fontSize: '30px', fontWeight: 'bold' }}>Blackjack</div>
        </header>

        <div style={{ display: 'flex' }}>
          <nav style={{ width: '240px', padding: '15px', backgroundColor: '#f0f0f0', height: '100vh', position: 'fixed' }}>
            <ul style={{ listStyleType: 'none', padding: 0, fontSize: '20px' }}>
              <li><Link to="/" style={{ textDecoration: 'none', color: '#333' }}>Game</Link></li>
              <li><Link to="/past-hands" style={{ textDecoration: 'none', color: '#333' }}>Past Hands</Link></li>
              <li><Link to="/strategy" style={{ textDecoration: 'none', color: '#333' }}>Strategy</Link></li>
              <li><Link to="/how-to" style={{ textDecoration: 'none', color: '#333' }}>How-To</Link></li>
              <li>
                <label htmlFor="deck-select" style={{ fontSize: '20px' }}>Decks: </label>
                <select id="deck-select" value={numDecks} onChange={handleDeckChange} style={{ fontSize: '18px' }}>
                  {[...Array(8).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </li>
            </ul>
            <StatisticsList />
          </nav>

          <div style={{ marginLeft: '260px', width: '100%' }}>
            <Routes>
              <Route path="/" element={
                <div style={contentStyle}>
                  <div style={handContainerStyle}>
                    <h2 style={labelStyle}>Dealer</h2>
                    <div>
                      {dealerHand.map((card, index) => (
                        <span key={index} style={index === 1 && !dealerTurn ? hiddenCardStyle : cardStyle}>
                          {index === 1 && !dealerTurn ? '🂠' : `${card.value}${card.suit}`}
                        </span>
                      ))}
                    </div>
                    <h2 style={scoreStyle}>Score: {dealerTurn ? calculateHandValue(dealerHand) : calculateHandValue([dealerHand[0]])}</h2>
                  </div>

                  {playerHands.map((hand, index) => (
                    <div key={index} style={getHandContainerStyle(index)}>
                      <h2 style={labelStyle}>Player {index + 1}</h2>
                      <div>
                        {hand.map((card, cardIndex) => (
                          <span key={cardIndex} style={cardStyle}>
                            {card.value}{card.suit}
                          </span>
                        ))}
                      </div>
                      <h2 style={scoreStyle}>Score: {calculateHandValue(hand)}</h2>
                      {index === currentHandIndex && !playerOptionsDisabled && (
                        <div style={buttonsContainerStyle}>
                          <button style={buttonStyle} onClick={hit}>Hit</button>
                          <button style={buttonStyle} onClick={stand}>Stand</button>
                          <button style={buttonStyle} onClick={doubleDown}>Double Down</button>
                          <button style={buttonStyle} onClick={splitPairs}>Split Pairs</button>
                          <button style={buttonStyle} onClick={takeInsurance}>Insurance</button>
                        </div>
                      )}
                      {playerOptionsDisabled && (
                        <button style={buttonStyle} onClick={() => showMoveAdvice("The correct move was Stand.")}>?</button>
                      )}
                    </div>
                  ))}

                  {playerOptionsDisabled && (
                    <button style={buttonStyle} onClick={startNewRound}>Play Another Round</button>
                  )}
                </div>
              } />
              <Route path="/past-hands" element={
                <div style={{ padding: '20px', textAlign: 'center' }}>
                  <h2>Past Hands</h2>
                  <table style={{ width: '80%', margin: '20px auto', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={tableHeaderStyle}>Player Hand Value</th>
                        <th style={tableHeaderStyle}>Dealer Hand Value</th>
                        <th style={tableHeaderStyle}>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastHands.map((hand, index) => (
                        <tr key={index}>
                          <td style={tableCellStyle}>{hand.playerValue}</td>
                          <td style={tableCellStyle}>{hand.dealerValue}</td>
                          <td style={tableCellStyle}>{hand.handResult}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              } />
              <Route path="/strategy" element={<StrategyTable />} />
              <Route path="/how-to" element={<div>How to Play Page</div>} />
            </Routes>
          </div>
        </div>
      </div>

      {showPopup && (
        <div style={{
          position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          padding: '20px', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
        }}>
          <h3>Move Advice</h3>
          <p>{popupContent}</p>
          <button onClick={() => setShowPopup(false)} style={buttonStyle}>Close</button>
        </div>
      )}
    </Router>
  );
};

export default Blackjack;
