import React from 'react';
import Blackjack from './Blackjack';

const App = () => {
  return (
    <div className="App">
      <Blackjack />
    </div>
  );
};

export default App;